import { ModalEvent, ModalHook, useModal } from './useModal';
import useModalWithHash from './useModalWithHash';

// This function will return either a modal with or without the hash based on the feature flag
// Part of an experiment to test closing the modal on browser back.
// Roll back to useModal directly if the experiment fails.
export function useModalBasedOnFeatureFlag<E extends ModalEvent>(
  featureFlagEnabled: boolean,
  modalViewKey: string,
): ModalHook<E> {
  const modal = useModal<E>();
  const modalWithHash = useModalWithHash<E>(modalViewKey);

  return featureFlagEnabled ? modalWithHash : modal;
}
